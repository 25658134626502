<template>
  <div id="term-of-use">
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> TERMS OF USE </v-icon>
          </v-card-title>
          <v-card-text>Last updated on 01/12/2021</v-card-text>
          <v-card-text>
            The website accessible through the electronic address https://merchanto.org (the WEBSITE) has been created
            by SIA "LeadProm Media". Our service helps issuers and merchants to increase card acceptance, stop card
            fraud, recover lost revenue and eliminate chargebacks from both fraud and customer service disputes. We
            enable data to be shared between merchants and card issuers.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 1. GENERAL INFORMATION </v-icon>
          </v-card-title>
          <v-card-text>
            1.1. SIA "LeadProm Media" have their official address: Matīsa iela, 61 - 31, Rīga, Latvia, LV-1009. We have
            (registration number) and are registered in (Name of the Registry).
            <br /><br />
            1.2. You can contact Merchanto through the following email address: support@merchanto.org, through which we
            will attend to any query, complaint or suggestion related to the use of the WEBSITE.
            <br /><br />
            1.3. This text includes the Terms of Use that regulate the use of services and functionalities of the
            WEBSITE, as well as the cases of contact and sending information through it.
            <br /><br />
            1.4. Our Privacy Policy and our Cookies Policy for Clients and Prospects complement these Terms of Use.
            <br /><br />
            1.5. We recommend that you read this information carefully and periodically check its content for any
            updates.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 2. GENERAL CONDITIONS OF USE </v-icon>
          </v-card-title>
          <v-card-text>
            2.1. These Terms of Use are the legally binding contract between Merchanto and, on the other hand, the
            prospects or/and visitors of the Website, the latter understood as any natural person, or representative of
            a legal person who, freely and voluntarily, consults, uses, or requests information about the services
            Merchanto or use the Website, which also includes visiting the Website, through the means established on the
            WEBSITE. All further communications between Merchanto and Prospects will be detailed in the bilateral
            commercial agreement, which includes all binding obligations between the parties, such as the price, means
            of payment and other contractual conditions.
            <br /><br />
            2.2. Sending information through the WEBSITE implies submission to the provisions of these Terms of Use,
            without prejudice to any other Terms of Use that may be applicable and, in particular, other Terms of Use of
            contract that the parties may expressly agree to.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 3. PROSPECT REGISTRATION </v-icon>
          </v-card-title>
          <v-card-text>
            3.1. The WEBSITE does not require specific registration for its use; however, there are means for you to
            provide personal contact information for our advisors to contact you to provide specific information on the
            Terms of Use of our services. The treatment of personal data sent through the electronic means enabled by
            Merchanto will be specifically regulated by our Privacy Policy and Cookie Policy for Clients and Prospects.
            <br /><br />
            3.2. The WEBSITE does not provide information or references to prices of our services; nor does it provide
            means of payment for the acquisition of products or services. The WEBSITE does not establish electronic
            means of payment and you must refrain from providing banking or financial information through the electronic
            forms available on the WEBSITE.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 4. USE OF THE WEBSITE </v-icon>
          </v-card-title>
          <v-card-text>
            4.1. The information published on the WEBSITE will be free.
            <br /><br />
            4.2. The information contained in the WEBSITE is for informational purposes and does not provide any type of
            recommendation, therefore, what is published here should not be considered as legal, financial, or
            investment advice. Merchanto does not assume responsibility that the information contained in this site
            meets the expectations of the prospects, likewise, it will not respond for the veracity, accuracy, or
            integrity of the information that is not of its creation and from which another source is indicated.
            <br /><br />
            4.3. The prospect expressly accepts that the use of the WEBSITE will be carried out under his sole and
            exclusive responsibility.
            <br /><br />
            4.4. When browsing and using the WEBSITE, the prospect agrees not to carry out any conduct that could damage
            the image, interests, or rights of Merchanto or third parties, nor actions that could damage or render the
            WEBSITE unusable, or that in any other way could prevent the normal operation of the WEBSITE. Specifically,
            the prospect agrees to:
            <ul>
              <li>
                4.4.1. Make diligent, correct, and lawful use of the WEBSITE, respecting the current legislation and, in
                particular, that relating to intellectual and industrial property.
              </li>
              <li>
                4.4.2. Periodically review these Terms of Use, checking the changes that, where appropriate, Merchanto
                had implemented.
              </li>
              <li>
                4.4.3. Review the consented communications that, where appropriate, Merchanto could send with
                information about these Terms of Use.
              </li>
              <li>
                4.4.4. Not to use the WEBSITE for commercial purposes; for example, collecting information or content to
                provide other services that may be a competition to Merchanto.
              </li>
              <li>
                4.4.5. Do not modify or try to modify the WEBSITE, or take actions aimed at copying or simulating its
                appearance or functions.
              </li>
              <li>
                4.4.6. Not to carry out actions that involve the introduction of computer viruses, worms, Trojans, or
                any other kind of malicious code intended to interrupt, destroy or limit the operation of the WEBSITE.
              </li>
              <li>
                4.4.7. Do not use reverse engineering techniques and/or decipher, decompile or use any other system
                intended to know the source code of the WEBSITE or any element protected by intellectual property
                regulations.
              </li>
            </ul>
            <br /><br />
            4.5. Merchanto may update, modify or eliminate the content of this WEBSITE or limit and/or prevent access to
            it at any time and without prior notice, mainly when, in your opinion, there are technical difficulties
            beyond Merchanto that decrease or cancel the security levels adopted for the proper functioning of said
            WEBSITE or the protection of the prospect's personal data.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 5. LIMITATION OF LIABILITY </v-icon>
          </v-card-title>
          <v-card-text>
            5.1. If you rely on Merchanto or any material available through the WEBSITE, including the content, you do
            so at your own risk and that the services offered here are provided following the Terms of Use established
            particularly for the service. You understand that there may be delays, omissions, interruptions,
            inaccuracies, and/or other problems with the information, products, content, and services provided on the
            Website. Merchanto does not guarantee that the WEBSITE satisfies the Prospect’s and Visitor’s requirements.
            <br /><br />
            5.2. You expressly understand and agree that Merchanto shall not be liable for any direct, indirect,
            incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of
            profits, goodwill, use, data, loss of business, or other intangible losses (even if Merchanto has been
            advised of the possibility of such damages), resulting from:
            <ul>
              <li>5.2.1. The use of any information available through the Website;</li>
              <li>5.2.2. The use or the inability to use the WEBSITE;</li>
              <li>5.2.3. Unauthorized access to or alteration of your transmissions or data;</li>
              <li>5.2.4. Or any other matters relating to the Website.</li>
            </ul>
            <br /><br />
            5.3. The WEBSITE works through an open network that is naturally exposed to various threats that make the
            Site not always secure; For this reason, the prospect or/and the visitor is responsible for adopting the
            appropriate technical measures to reasonably control these threats, as well as having up-to-date systems in
            charge of detecting malicious software.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 6. CONTENT OF THE WEBSITE </v-icon>
          </v-card-title>
          <v-card-text>
            6.1. The intellectual, moral, and patrimonial property rights in their entirety, of all the content of the
            WEBSITE, including but not limited to graphic design, logos, prospect interface, source codes, texts,
            graphics, photographs, works expressed by analogous procedure photography, videos, sound material, etc., are
            the property of Merchanto, its licensors or of any parent company or subsidiary thereof and are protected by
            current national and international legislation. For this reason, the partial or total reproduction of any
            content on this WEBSITE is prohibited, as well as any deformation, modification, alteration, or attack that
            damages the interests or reputation of Merchanto.
            <br /><br />
            6.2. Merchanto is the only one empowered to exercise the rights of reproduction, distribution,
            communication, publication, and transformation of any content published on the WEBSITE. Any prospect who
            decides to make a partial or total reproduction of the content displayed here, must request express
            authorization from Merchanto and give the corresponding credit to it as the author of the literary or
            audiovisual works unless fragments of the content function by way of quotation or for your analysis, comment
            or critical judgment.
            <br /><br />
            6.3. All photographs of people that are published on the Merchanto as part of advertising campaigns will
            have the authorization of the person whose image appears in them, therefore, Merchanto has the exclusive
            right to reproduce and distribute them and they cannot be altered under any circumstances.
            <br /><br />
            6.4. The use of the WEBSITE by any prospect does not imply the transfer of any right over the intellectual
            and/or industrial property of the present and future content. Any assignment of rights that Merchanto on
            this WEBSITE must be in writing at all times. Likewise, all trademarks and trade names published on the
            WEBSITE are the property of Merchanto, its licensors or of any parent company or subsidiary thereof.
            <br /><br />
            6.5. Merchanto, as the owner or licensee of the trademarks and trade names contained in the WEBSITE, is the
            only one who has the exclusive right to use them in economic traffic, and their use by third parties without
            the express consent of the owner is prohibited.
            <br /><br />
            6.6. Without prejudice to the corresponding actions, Merchanto may urge the cessation of any illegal
            activity that violates its intellectual and industrial property rights and demand compensation for material
            and moral damages caused.
            <br /><br />
            6.7. You acknowledge that the content includes certain trademarks and service marks owned by SIA "LeadProm
            Media" (Matīsa iela, 61 - 31, Rīga, Latvia, LV-1009), including (Domain Name), and certain logotypes, as
            well as trademarks owned by other information providers. You agree not to copy, use or otherwise infringe
            upon these trademarks or service marks. You further agree that you will not alter or remove any trademark,
            copyright, or other notice from any copies of the content. If you operate a Website and wish to link to the
            service, you may not do so without SIA "LeadProm Media"'s express written approval. Requests for approval to
            link to the service may be directed to the address provided above.
            <br /><br />
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 7. ESTABLISHMENT OF LINKS TO THE WEBSITE </v-icon>
          </v-card-title>
          <v-card-text>
            7.1. The establishment of links to this WEBSITE is prohibited without authorization from Merchanto. In case
            of detecting a breach of this term, the legal measures that proceed for this purpose may be adopted.
          </v-card-text>
          <v-card-title class="flex-nowrap">
            <v-icon class="text--primary me-2"> 8. THIRD PARTY LINKS </v-icon>
          </v-card-title>
          <v-card-text>
            8.1. The existence of links within the WEBSITE to pages or other websites of third parties does not entail
            the responsibility of Merchanto on the content, operation, or veracity of the information published therein.
            The prospect acknowledges and accepts that Merchanto has no control over the content published on
            third-party websites, even if they are linked from the WEBSITE.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Privacy.vue',
};
</script>

<style lang="scss" scoped>
#term-of-use {
  ul > li {
    list-style: none;
  }
}
</style>
